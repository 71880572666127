"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reproductionService = exports.birthService = exports.cattleTreatmentsService = exports.cattleBatchesService = exports.cattleDiseases = exports.cattleSpecies = exports.cattleUnitsService = exports.grazingDaysService = exports.expensesService = exports.incomesService = exports.parcelsService = exports.grazingParcelsService = exports.exploitationsService = exports.parcelBatchesService = void 0;
const EntityService_1 = require("./generic/EntityService");
const CachedEntityService_1 = require("./generic/CachedEntityService");
const MappedEntityService_1 = require("./generic/MappedEntityService");
const dataMappers_1 = require("../utils/dataMappers");
const ExploitationEntityService_1 = require("./generic/ExploitationEntityService");
const translations_1 = require("../utils/translations");
var notebook_batches_service_1 = require("./notebook.batches.service");
Object.defineProperty(exports, "parcelBatchesService", { enumerable: true, get: function () { return notebook_batches_service_1.parcelBatchesService; } });
exports.exploitationsService = Object.assign(new CachedEntityService_1.CachedEntityService(new EntityService_1.EntityService('exploitations')), {
    getLabel(exploitation) {
        if (!exploitation)
            return '';
        return exploitation.name
            ? exploitation.fiscal_id
                ? `${exploitation.name} (${exploitation.fiscal_id})`
                : exploitation.name
            : exploitation.fiscal_id;
    },
});
const [parcelMapper, parcelMapperR] = dataMappers_1.genBothMappers({
    divide: { pasture_area: 10000 },
    nested: { exploitation_parcel: { divide: { declared_area: 10000 } } },
});
exports.grazingParcelsService = new MappedEntityService_1.MappedExploitationEntityService('grazing/parcels', parcelMapper, parcelMapperR);
const _parcelsService = new MappedEntityService_1.MappedExploitationEntityService('parcels', parcelMapper, parcelMapperR);
exports.parcelsService = Object.assign(_parcelsService, {
    getLabel(p) {
        if (!p)
            return '';
        const parcel = p.exploitation_parcel;
        // let result = parcel.name || `<${parcel.id}>`;
        // if (parcel.parcel_num != null) result = `${parcel.parcel_num} - ` + result;
        return parcel.name || `<${parcel.id}>`;
    },
    getSubLabel(p) {
        if (!p)
            return '';
        const parcel = p.exploitation_parcel;
        let result = '';
        if (parcel.parcel_num != null)
            result = translations_1.t('notebook.forms.parcel.parcel_num') + ': ' + parcel.parcel_num;
        return result;
    },
});
const [economicMapper, economicMapperR] = dataMappers_1.genBothMappers({
    divide: { value: 100 },
});
exports.incomesService = new MappedEntityService_1.MappedExploitationEntityService('economics/incomes', economicMapper, economicMapperR);
exports.expensesService = new MappedEntityService_1.MappedExploitationEntityService('economics/expenses', economicMapper, economicMapperR);
exports.grazingDaysService = new ExploitationEntityService_1.ExploitationEntityService('grazing/days');
var notebook_cattle_service_1 = require("./notebook.cattle.service");
Object.defineProperty(exports, "cattleUnitsService", { enumerable: true, get: function () { return notebook_cattle_service_1.cattleUnitsService; } });
Object.defineProperty(exports, "cattleSpecies", { enumerable: true, get: function () { return notebook_cattle_service_1.cattleSpecies; } });
Object.defineProperty(exports, "cattleDiseases", { enumerable: true, get: function () { return notebook_cattle_service_1.cattleDiseases; } });
Object.defineProperty(exports, "cattleBatchesService", { enumerable: true, get: function () { return notebook_cattle_service_1.cattleBatchesService; } });
Object.defineProperty(exports, "cattleTreatmentsService", { enumerable: true, get: function () { return notebook_cattle_service_1.cattleTreatmentsService; } });
Object.defineProperty(exports, "birthService", { enumerable: true, get: function () { return notebook_cattle_service_1.birthService; } });
Object.defineProperty(exports, "reproductionService", { enumerable: true, get: function () { return notebook_cattle_service_1.reproductionService; } });

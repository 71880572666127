import React, { ComponentType, useContext } from 'react';
import { t } from '@smartwood/common-client/utils/translations';
import { ValidationChain, ValidableFieldProps } from './validation';
import { SWCheckBox, SWNumberField, SWTextField, SWSelect, FormContext, FormContextType } from './Form';

export const DynamicFormGroup = ({ translationKey, specs }: DynamicFormProps) => {
  const { model } = useContext<FormContextType>(FormContext);
  return <>
    {Object.entries(specs)
      .filter(([_f, spec]) => !spec.condition || spec.condition(model))
      .map(([field, spec]) => {
        const label = t(`${translationKey}.${field}`);
        const props = { ...spec.props, key: field, label, field, disabled: spec.disabled && !!spec.disabled(model) };
        switch (spec.type) {
          case 'string':
            return <SWTextField {...props} validations={spec.validators} validateWith={spec.validateWith} />;
          case 'date':
            return <SWTextField {...props} type='date' validations={spec.validators} validateWith={spec.validateWith} />;
          case 'number':
            return <SWNumberField {...props} validations={spec.validators} validateWith={spec.validateWith} maxDecimals={spec.maxDecimals} />;
          case 'boolean':
            return <SWCheckBox {...props} />;
          case 'select':
            return <SWSelect
              {...props}
              validations={spec.validators}
              options={mapSelectOptions(spec.values, spec.translationKey)} />;
          case 'custom':
            const Component = spec.Component;
            return <Component {...props} field={field} validations={spec.validators} />;
          default:
            console.error('Unknown field type', spec);
            return null;
        }
      })
    }
  </>;
};

const mapSelectOptions = (values: string[], tranKey: string = '') =>
  [
    { label: t('Choose'), value: null },
    ...values.map(v => ({ label: tranKey ? t(tranKey + '.' + v) : v, value: v }))
  ];

interface DynamicFormProps {
  translationKey: string;
  specs: DynamicFormFieldSpecs;
}

export type DynamicFormFieldSpecs = { [field: string]: FormFieldSpec; };

type ValidableDynamicField = {
  validators?: ValidationChain;
  validateWith?: string[];
};

export type FormFieldSpec =
  {
    condition?: (model: any) => boolean | null | undefined;
    disabled?: ((model: any) => boolean | null | undefined);
    props?: object;
  } & (
    { type: 'string' | 'date' | 'multiline'; } & ValidableDynamicField |
    { type: 'number'; maxDecimals?: number; } & ValidableDynamicField |
    { type: 'boolean'; } |
    { type: 'select'; values: string[]; translationKey?: string; } & ValidableDynamicField |
    { type: 'custom'; Component: ComponentType<ValidableFieldProps>; } & ValidableDynamicField
  );
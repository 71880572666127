import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { t } from '@smartwood/common-client/utils/translations';
import { getRoles, logout } from '@smartwood/common-client/services/app.service';
import logoh from '../../resources/logoh.svg';
import { AppBar, Hidden, IconButton, Menu, ListItem, ListItemText } from '@material-ui/core';
import { Modal } from '../../components/generic/Modal';
import MenuIcon from '@material-ui/icons/Menu';
import { useSession } from './SessionContext';
import { faUser, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserForm } from '../admin/UserForm';

const Logo = styled.img`height: 50px;`;
const SWAppBar = styled(AppBar)`
  position: fixed;
  top: 0;
  background: white;
  flex-flow: row;
  padding: 10px 15px;
  z-index: 1300;
  .button {
    margin-left: -10px;
  }
  color: black;
`;
export const SWHeader = ({ drawer, toggleDrawer }: SWHeaderProps) => {
  const { user } = useSession();
  return (
    <SWAppBar position='static'>
      {drawer && <Hidden smUp>
        <IconButton className='button' onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
      </Hidden>}
      <a href='/'><Logo src={logoh} alt='SmartWood logo' /></a>
      <UserZone user={user} />
    </SWAppBar>
  );
};

const UserZoneContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  font-size: 1.25em;
  svg {margin: 0 10px; }
  cursor: pointer;
  ${({ theme }) => theme.breakpoints.down('sm')} {
      padding: 0;
    .ocultable {display: none; }
    svg {font-size: 1.5em; margin: 15px; }
  }
`;

const UserZone = ({ user }) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [editUser, setEditUser] = useState<boolean>(false);
  const history = useHistory();
  const userRole = getUserRole('admin');
  if (!user) return null;
  return <>
    <UserZoneContainer onClick={e => setMenuAnchor(e.currentTarget)}>
      <FontAwesomeIcon className='icon' icon={faUser} />
      <label className='ocultable'>{user.email}</label>
      <FontAwesomeIcon className='ocultable' icon={faCaretDown} />
    </UserZoneContainer>
    {menuAnchor &&
      <Menu open anchorEl={menuAnchor} onClose={() => setMenuAnchor(null)}>
        {userRole === 'admin' &&
          <ListItem button onClick={() => { history.push('/admin/users'); }}>
            <ListItemText primary={t('admin.adminPanel')} />
          </ListItem>
        }
        <ListItem button onClick={() => {
          setEditUser(true);
        }}>
          <ListItemText primary={t('Usuario')} />
        </ListItem>
        <ListItem button onClick={() => { logout(); history.push('/'); }}>
          <ListItemText primary={t('app.logout')} />
        </ListItem>
      </Menu>
    }
    <Modal open={editUser} minWidth='400px' onClose={() => setEditUser(false)}>
      <UserForm
        entry={user}
        fromCurrentUser={true}
        onClose={() => {
          setEditUser(false);
        }}
      />
    </Modal>
  </>;
};

const getUserRole = (roleName: string) => {
  const roles = getRoles();
  if (!roles) return '';
  const role = roles.find(e => e.role === roleName);
  if (!role) return '';
  return role.role;
};

interface SWHeaderProps {
  toggleDrawer?: () => void;
  drawer?: boolean;
}
